






























import { Component, Vue, Watch } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import TabBase from '@/components/atoms/TabBase.vue'
import SelectMultiple from '@/components/atoms/SelectMultiple.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    TabBase,
    SelectMultiple,
    ButtonBase,
  },
})
export default class SeminarEdit extends Vue {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private seasonSettingId = this.$route.params.id

  private isProcessing = false

  private breadcrumbs = [
    { text: '設定', href: '/teacher/setting/top' },
    { text: '講習会設定', href: '' },
    { text: '', active: true },
    { text: '編集', active: true },
  ]

  private tabBaseDatas: { id: number; name: string }[] = []

  private subjectCode = ''

  private studyUnits: { value: string; text: string }[] = []

  private targetUnits: { value: string; text: string }[] = []

  private selectedUnits: string[] = []

  private selectedTargetUnits: string[] = []

  private gradeId = 0

  private seasonId = 0

  private gradeCode = ''

  @Watch('gradeId')
  onGradeIdChanged(newGradeId: number, oldGradeId: number) {
    if (oldGradeId !== 0) {
      this.loadChangedStudyUnits()
    }
  }

  private async loadChangedStudyUnits() {
    Vue.prototype.$loading.start()
    await this.loadStudyUnits(this.subjectCode, null)
    Vue.prototype.$loading.complete()
  }

  private get colortype(): string {
    return this.targetUnits.length > 0 ? 'blue' : 'pointer-events-none'
  }

  private get prevPage(): string {
    return `/teacher/setting/seminar?seasonId=${this.seasonId}#${this.gradeCode}`
  }

  private addUnit(): void {
    this.selectedUnits.forEach((unit_value: string) => {
      const obj = this.studyUnits.find((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      if (!obj) return
      this.targetUnits.push(Object.assign({}, obj))
      this.$set(obj, 'visible', false)
    })
  }
  private removeUnit(): void {
    this.selectedTargetUnits.forEach((unit_value: string) => {
      const obj = this.studyUnits.find((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      if (!obj) return
      this.targetUnits = this.targetUnits.filter((unit: { value: string; text: string }) => {
        return unit.value !== unit_value
      })
      this.$set(obj, 'visible', true)
    })
  }
  private upUnit(): void {
    // 一番上の項目選択時は動作しない
    if (this.selectedTargetUnits.includes(this.targetUnits[0].value)) return
    this.selectedTargetUnits.forEach((unit_value: string) => {
      const idx = this.targetUnits.findIndex((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      this.targetUnits = this.reverseTargetUnits(idx, idx - 1)
    })
  }
  private downUnit(): void {
    // 一番下の項目選択時は動作しない
    if (this.selectedTargetUnits.includes(this.targetUnits[this.targetUnits.length - 1].value)) return
    this.selectedTargetUnits.forEach((unit_value: string) => {
      const idx = this.targetUnits.findIndex((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      this.targetUnits = this.reverseTargetUnits(idx, idx + 1)
    })
  }

  private reverseTargetUnits(from_idx: number, to_idx: number): { value: string; text: string }[] {
    const result: { value: string; text: string }[] = []
    this.targetUnits.forEach((unit: { value: string; text: string }, index) => {
      if (index === from_idx) result.push(this.targetUnits[to_idx])
      else if (index === to_idx) result.push(this.targetUnits[from_idx])
      else result.push(unit)
    })
    return result
  }

  private filterStudyUnits(): void {
    this.targetUnits.forEach((t_unit: { value: string; text: string }) => {
      const obj = this.studyUnits.find((unit: { value: string; text: string }) => {
        return unit.value === t_unit.value
      })
      if (!obj) return
      this.$set(obj, 'visible', false)
    })
  }

  private async loadSeminarUnit() {
    await Vue.prototype.$http.httpWithToken.get(`/grades`).then((res: any) => {
      this.tabBaseDatas = res.data.map((grade: { id: number; name: string }) => {
        return {
          id: grade.id,
          name: grade.name,
        }
      })
    })

    await Vue.prototype.$http.httpWithToken.get(`/seasonSettings/${this.seasonSettingId}/setting`).then((res: any) => {
      this.breadcrumbs[this.breadcrumbs.length - 2].text = res.data.seasonSetting.seasonName
      this.breadcrumbs[
        this.breadcrumbs.length - 3
      ].href = `/teacher/setting/seminar?seasonId=${res.data.seasonSetting.seasonId}#${res.data.seasonSetting.gradeCode}`
      this.subjectCode = res.data.seasonSetting.subjectCode
      this.gradeId = res.data.seasonSetting.gradeId
      this.gradeCode = res.data.seasonSetting.gradeCode
      this.seasonId = res.data.seasonSetting.seasonId
      let selectedUnits: [] | null = null
      if (res.data.settings.length > 0) {
        selectedUnits = res.data.settings.map(
          (setting: { grade: string; sUnitName: string; publisherCurriculumsSUnitId: number }) => {
            return {
              value: setting.publisherCurriculumsSUnitId.toString(),
              text: `${setting.grade} ${setting.sUnitName}`,
            }
          }
        )
      }
      this.loadStudyUnits(res.data.seasonSetting.subjectCode, selectedUnits)
    })
  }

  private async postSeasonSettings() {
    if (this.isProcessing) return
    this.isProcessing = true

    const settings = this.targetUnits.map((unit: { value: string; text: string }) => {
      return {
        publisherCurriculumsSUnitId: parseInt(unit.value),
      }
    })

    await Vue.prototype.$http.httpWithToken
      .post(`/seasonSettings/${this.seasonSettingId}/setting`, { settings: settings })
      .then(() => {
        alert('登録しました。一覧に戻ります')
      })
      .catch((error: any) => {
        alert('エラーが発生しました。一覧に戻ります')
      })
      .finally(() => {
        this.$router.push({
          path: '/teacher/setting/seminar',
          query: { seasonId: this.seasonId.toString() },
          hash: this.gradeCode,
        })
        this.isProcessing = false
      })
  }

  private async createSeminarUnit() {
    Vue.prototype.$loading.start()
    await this.postSeasonSettings()
    Vue.prototype.$loading.complete()
  }

  private async loadStudyUnits(subjectCode: string, selectedUnits: any) {
    await Vue.prototype.$http.httpWithToken
      .get(`/publisherCurriculums/curriculumSUnitsBySubject`, {
        params: { gradeId: this.gradeId, subjectCode: subjectCode },
      })
      .then((res: any) => {
        this.studyUnits = res.data.units.map((unit: { id: number; sUnitName: string }) => {
          return {
            value: unit.id.toString(),
            text: `${this.getGradeName()} ${unit.sUnitName}`,
          }
        })
        if (selectedUnits) {
          this.targetUnits = selectedUnits
        }
      })
      .finally(() => {
        this.filterStudyUnits()
      })
  }

  private getGradeName(): string {
    const grade = this.tabBaseDatas.find((tabBaseData) => {
      return tabBaseData.id === this.gradeId
    })
    if (!grade) return ''
    return grade.name
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadSeminarUnit()
    Vue.prototype.$loading.complete()
  }
}
